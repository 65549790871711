import { ICliente } from './../../interfaces/ICliente';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { LoginService } from 'src/app/auth/services/login.service';
import { EventosService } from 'src/app/inicio/services/eventos.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { ClienteService } from '../../services/cliente.service';
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSidenav)
 sidenav!: MatSidenav;
  sesionLogout = false
  constructor(
    private observer: BreakpointObserver,
    private loginService: LoginService,
    private clienteService: ClienteService,
    private _router: Router) { }
  clientes: ICliente[]
  items: MenuItem[];
  clienteSelected: string
  url: string = ''
  ngOnInit() {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
          this.url = event.url;
          if (this.url == '/' || this._router.url == '/remisiones/inventario'
          || this._router.url == '/produccion/despachos'
          || this._router.url == '/produccion/despachosproductos'
          || this._router.url == '/produccion/pedidos'
          || this._router.url == '/master/sedes'
          || this._router.url == '/master/productos'
          || this._router.url == '/master/kits'
          || this._router.url == '/master/usuarios') {
            this.getclientes()
          }
      }
    });
    if (this._router.url == '/inicio'
    || this._router.url == "/remisiones/inventario"
    || this._router.url == '/produccion/despachos'
    || this._router.url == '/produccion/despachosproductos'
    || this._router.url == '/produccion/pedidos'
    || this._router.url == '/master/sedes'
    || this._router.url == '/master/productos'
    || this._router.url == '/master/kits'
    || this._router.url == '/master/usuarios') {
      this.url = '/'
      this.getclientes()
    }
  }

  getclientes(){
    this.clienteService.clientesSubject.subscribe((clientes: ICliente[]) => {
      if (clientes?.length > 0) {
        this.clientes = clientes
        this.clienteSelected = this.clientes[0].hash_id
      }
    })
  }

  ngAfterViewInit() {
    // se coloca timeout para evitar error de ERROR Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value for '@transform': 'void'. Current value: 'open'
    setTimeout(() => {
      this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      });
    }, 10)
  }

  /**
   * Metodo para cerrar la sesión
   * @returns {void}
   */
  logout(): void {
    this.sesionLogout = true;
    this.loginService.logout();
  }

  cambiarClave() {
    this._router.navigate(["/configuracion/cambiar-clave"]);
  }

  onClienteChange(event) {
    this.clienteService.setClienteHashSelected(event.value)
  }

}
