import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/auth/interfaces/login.interfaces';

@Injectable({
  providedIn: 'root'
})
export class SedesService {

  baseUrl: string = environment.baseUrl;
  public user: User;
  public token: string;
  constructor(private http: HttpClient) {
    this.user = JSON.parse(localStorage.getItem('user')) || {};
    this.token = JSON.parse(localStorage.getItem('access_token'))
  }
  getAll(parameters?:any): Observable<any> {
    return this.http.get(this.baseUrl + '/sedes',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      }),params: parameters
    });

  }

  getSedesByUsuario(): Observable<any> {
    return this.http.get(this.baseUrl + '/sedes/usuario',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });

  }

  add(datos): Observable<any> {
    return this.http.post(this.baseUrl + '/sedes', datos,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  update(datos, id): Observable<any> {
    return this.http.put(this.baseUrl + '/sedes/' + id, datos,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  delete(id): Observable<any> {
    return this.http.delete(this.baseUrl + '/sedes/' + id,{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
  getClientes(): Observable<any> {
    return this.http.get(this.baseUrl + '/clientes/all',{
      headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
      })
    });
  }
}
