import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { PrimeNGModule } from '../prime-ng/prime-ng.module';
import { LayoutComponent } from './pages/layout/layout.component';
import { OpcionesEnvioComponent } from './components/opciones-envio/opciones-envio.component';
import { FormsModule } from '@angular/forms';
import { DataTableComponent } from './components/data-table/data-table.component';
import { OpcionesEnvioProductosComponent } from './components/opciones-envio-productos/opciones-envio-productos.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {PanelMenuModule} from 'primeng/panelmenu';
import {MatSelectModule} from '@angular/material/select';
import { LayoutModule } from '@angular/cdk/layout';
@NgModule({
  declarations: [
    MenuComponent,
    LayoutComponent,
    OpcionesEnvioComponent,
    DataTableComponent,
    OpcionesEnvioProductosComponent
  ],
  exports: [
    MenuComponent,
    OpcionesEnvioComponent,
    DataTableComponent,
    OpcionesEnvioProductosComponent
  ],
  imports: [
    CommonModule,
    PrimeNGModule,
    FormsModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    PanelMenuModule,
    MatSelectModule,
    LayoutModule
  ]
})
export class SharedModule { }
