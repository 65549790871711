import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/auth/interfaces/login.interfaces';
import { environment } from 'src/environments/environment';
import { ICliente } from '../interfaces/ICliente';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {
  public clientesSubject: BehaviorSubject<ICliente[]> = new BehaviorSubject<ICliente[]>(null)
  public clienteHashSelectedSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  baseUrl: string = environment.baseUrl;
  public user: User;
  public token: string;

  constructor(private http: HttpClient) {
    this.user = JSON.parse(localStorage.getItem('user')) || {};
    this.token = JSON.parse(localStorage.getItem('access_token'))
    this.getAllClientesByUser()
  }

  async getAllClientesByUser() {
    try {
      const data = await this.http.get(this.baseUrl + '/clientes/by-user', {
        headers: new HttpHeaders({
          'Authorization': `Bearer ${this.token}`
        })
      }).toPromise()
      if (data['data'].length > 0) {
        this.clientesSubject.next(data['data']);
        this.setClienteHashSelected(data['data'][0].hash_id)
      }
    } catch (error) {
      throw error;
    }
  }

  /**
     * Getter for clientesSubject
     */
  get clientesSubject$(): Observable<ICliente[]> {
    return this.clientesSubject.asObservable();
  }

  /**
     * Getter for clienteHashSelectedSubject
     */
  get clienteHashSelectedSubject$(): Observable<string> {
    return this.clienteHashSelectedSubject.asObservable();
  }

  /**
   * Setter for clienteHashSelectedSubject
   */
   setClienteHashSelected(cliente_hash_id: string) {
    this.clienteHashSelectedSubject.next(cliente_hash_id);
  }
}
