import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EventosService } from 'src/app/inicio/services/eventos.service';
import { RemisionesService } from 'src/app/remisiones/services/remisiones.service';
import { SedesService } from 'src/app/sedes/services/sedes.service';
import { GeoLocation } from '../../interfaces/GeoLocationInterface';
import { ClienteService } from '../../services/cliente.service';
import { GeolocationService } from '../../services/geolocation.service';

@Component({
  selector: 'app-opciones-envio-productos',
  templateUrl: './opciones-envio-productos.component.html',
  styleUrls: ['./opciones-envio-productos.component.css']
})
export class OpcionesEnvioProductosComponent implements OnInit {

  sedes:any[]=[];
  selectedSede:any={};
  usuario:any;
  guia:string;
  confirmar:boolean=true;
  exclusivo:boolean=false;
  transportadora:string='';
  geoLocation: GeoLocation = {
    lat: 0,
    lng: 0,
  }
  observaciones:string='';
  constructor(
    private remisionesService:RemisionesService,
    private sedesService: SedesService,
    private _geolocation: GeolocationService,
    private clienteService: ClienteService,
  ) { }
  @Input() productos:any[]=[];
  @Input() labelSave:string="Enviar";
  @Input() displayModal:boolean=false;
  @Output() onCancel = new EventEmitter();
  @Output() onSave = new EventEmitter<string>();

  ngOnInit(): void {
    this._geolocation.getPosition().then(data => {
      this.geoLocation = data
    })
    this.clienteService.clienteHashSelectedSubject.subscribe(hash_id => {
      if (hash_id) {
        this.sedesService.getAll({usuarios:1, cliente_hash_id: hash_id}).subscribe((response) => {
          this.sedes = response.filter(sede => sede.id != 1);
          console.log(response[0])
          this.selectedSede = response[1];
          this.changeSede()
          if (this.productos.length>0){
            console.log(this.productos);
            // this.guia=this.productos[0].serial+" - "+this.productos[this.productos.length-1].serial;
          }
        });
      }
    })

  }
  changeSede(){
    this.usuario=this.selectedSede.usuarios[0];
  }
  retirar(item){
    item.disabled=true;
  }

  cancel(){
    this.onCancel.emit();
  }
  despachar(){

    var json:any={
      verificados_seriales:this.productos.filter(item => item.disabled != true),
      usuario_recibe: this.usuario,
      exclusiva:this.exclusivo,
      confirmar:this.confirmar,
      guia:this.guia,
      transportadora:this.transportadora,
      sede: this.selectedSede,
      lat: this.geoLocation.lat,
      lng: this.geoLocation.lng,
      observaciones:this.observaciones
    };
    this.remisionesService.despachar({remision:json}).subscribe((resp) => {
      this.onSave.emit(resp.message);
    });

  }


}
