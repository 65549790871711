<!-- <app-menu></app-menu> -->
<!-- <p-sidebar [(visible)]="display">
  <div class="img">
    <h4>Logo</h4>
  </div>
  <p-panelMenu [model]="items" [style]="{ width: '100%' }"></p-panelMenu>
</p-sidebar> -->
<mat-toolbar color="primary" class="example-toolbar">
  <button mat-icon-button (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <ng-template #titulo>
    <h1 class="example-app-name">Inventario</h1>
  </ng-template>
  <div class="p-mx-4" style="width: 15vw;" *ngIf="url == '/'
  || url == '/remisiones/inventario'
  || url == '/produccion/despachos'
  || url == '/produccion/despachosproductos'
  || url == '/produccion/pedidos'
  || url == '/master/sedes'
  || url == '/master/productos'
  || url == '/master/kits'
  || url == '/master/usuarios'; else titulo">
    <mat-select [(ngModel)]="clienteSelected" name="nombre" (selectionChange)="onClienteChange($event)">
      <mat-option *ngFor="let c of clientes" [value]="c.hash_id" pla>
        {{c.nombre}}
      </mat-option>
    </mat-select>
  </div>
  <span class="example-spacer"></span>
  <button mat-button class="example-icon favorite-icon" (click)="cambiarClave()">
    <mat-icon>lock</mat-icon> Cambiar clave
  </button>
  <button mat-button class="example-icon" (click)="logout()">
    <mat-icon>power_settings_new</mat-icon> Cerrar sesión
  </button>
</mat-toolbar>

<mat-sidenav-container class="example-sidenav-container">
  <mat-sidenav #sidenav="matSidenav">
    <!-- <mat-nav-list>
      <a mat-list-item routerLink=".">Este es un menu</a>
    </mat-nav-list> -->
    <app-menu></app-menu>
  </mat-sidenav>

  <mat-sidenav-content>
    <div style="background-color: white; margin-left: 1vw; margin-right: 1vw;">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
